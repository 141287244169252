@import '../../../../styles/main.scss';

.login-container {
    position: fixed;
    right: 10px;
    white-space: nowrap;
    margin: 0 0.5rem;

    @include desktop {
        position: static;
    }

    .login-button {
        color: $highlight-blue;
        &.active {
            color: $dark-blue;
        }

        .login-text {
            display: none;
            position: relative;
            top: -1.2rem;

            @include desktop {
                display: initial;
            }
        }
    }

    .login-dropdown {
        display: none;
        position: fixed;
        top: 4.4rem;
        right: 1rem;
        color: $highlight-blue;
        background: $white;
        border: 1px solid $lightgrey;
        padding: 0px 30px 0px 30px;

        &.active {
            display: block;
        }

        @media screen and (min-width: $mobile-width) {
            top: 5.2rem;
            right: 10rem;
        }

        .login-dropdown-list {
            list-style: none;
            min-width: pToR(238);
            min-height: pToR(50);

            .login-dropdown-list-item {
                padding: 15px;
                &:nth-child(1) {
                    border-bottom: 1px solid $lightgrey;
                }
            }
        }
    }
}

@import './../../../../styles/main.scss';

.model-not-supported-wrapper {
    min-height: 200px;
    width: 100%;

    .how-to-description {
        display: none;
    }

    .event-heading {
        height: 88px;
        color: #4d4d4d;
        font-family: $f1-font-light;
        font-size: 34px;
        line-height: 36px;
        margin: 40px;

        @include desktop {
            height: 100px;
            font-size: 80px;
            line-height: 90px;
            margin: 40px 40px 40px 100px;
        }

        @include desktop {
            font-size: 48px;
            line-height: 58px;
        }
    }

    .model-message {
        font-family: $f1-font-light;
        font-size: 30px;
        font-size: 40px;
        text-align: center;
        color: #4d4d4d;
        margin-top: 40px;

        p {
            font-size: 20px;
            margin-top: 20px;

            a {
                color: #1700f4;
            }
        }
    }
}

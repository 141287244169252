.brand-lincoln {
    .maintenance-schedule-details-page-title,
    .maintenance-schedule-details-page-subtext,
    .maintenance-schedule-details-page-olm-text,
    .service-schedule-vehicle-details h2,
    div.maintenance-carousel h2.carousel-heading,
    div.maintenance-carousel .carousel-body .toggle-subheading,
    div.maintenance-carousel .carousel-body .toggle-description,
    .service-schedule-vehicle-details-info .service-schedule-odometer-label,
    .service-schedule-vehicle-details-info .service-schedule-odometer,
    .maintenance-error .error-message h1,
    .maintenance-error .error-message p {
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }

    .maintenance-schedule-details-page-title {
        font-size: 32px;
        letter-spacing: 2px;
    }

    div.maintenance-carousel h2.carousel-heading,
    div.maintenance-carousel .carousel-body .toggle-subheading {
        font-size: 24px;
        letter-spacing: 2px;
    }

    .maintenance-schedule-details-page-subtext,
    .maintenance-schedule-details-page-olm-text,
    div.maintenance-carousel .carousel-body .toggle-description,
    .maintenance-error .error-message p {
        font-size: 18px;
    }

    div.maintenance-carousel .carousel-body .toggle-description a {
        font-family: $proxima-nova-semi-bold;
    }

    .service-schedule-vehicle-details {
        box-shadow: none;
        border: 1px solid $brand-gray3;
        background: $brand-gray1;
    }
    .maintenance-schedule-details-page-olm-text {
        padding-bottom: 20px;
        letter-spacing: 1px;
    }
}

$fds-font--ford-icons__play-arrow: '\e90b';
.brand-ford {
    .notification-banner-container {
        opacity: 0;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        animation-name: dropdown;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        animation-timing-function: linear;
        z-index: 1000;
        margin-top: 0;
        font-size: 14px;
        @include mobile {
            animation-delay: 2s;
            animation-duration: 0.6s;
        }

        .notification-banner {
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background-color: $fds-color-white;
            overflow: visible;
            position: relative;
            @include mobile {
                padding: 0;
            }
            p,
            a {
                position: relative;
                z-index: 101;
                display: inline;
                font-family: $f1-font-regular;
                line-height: 24px;
                color: $breadcrumb-blue;
                animation: textColorChanges;
                animation-delay: 1.25s;
                animation-duration: 0.6s;
                @include mobile {
                    animation-delay: 2.5s;
                    animation-duration: 1s;
                }
            }
            p {
                @include tablet {
                    vertical-align: text-bottom;
                }
            }
            a:hover {
                color: $grabber-blue;
            }

            .notification-wrapper {
                position: relative;
                align-items: center;
                display: flex;
                padding-right: 10px;
                padding-top: 16px;
                padding-bottom: 16px;
                height: 100%;
                z-index: 101;

                .notification-content {
                    position: relative;
                    margin-left: 15px;
                    z-index: 101;
                    width: 100%;
                    @include mobile {
                        display: inline;
                        font-size: 16px;
                        color: #00095b;
                        font-family: $f1-font-regular;
                        line-height: 24px;
                    }
                    .chevron-icon {
                        position: relative;
                        @include mobile {
                            top: 5px;
                        }
                        &::before {
                            font-family: FdsFordIcons, sans-serif;
                            content: $fds-font--ford-icons__play-arrow;
                            color: $breadcrumb-blue;
                            font-size: 15px;
                            position: relative;
                            bottom: 2px;
                            @include mobile {
                                bottom: 4px;
                            }
                        }
                    }
                }

                a {
                    display: inline-flex;
                }
            }

            button {
                border: none;
                background-color: transparent;
                height: 14px;
                margin: auto 0;
            }

            .close-icon {
                position: relative;
                cursor: pointer;
                z-index: 101;
                height: 22px;
                width: 22px;
                margin-top: -4px;
                @include mobile {
                    margin-bottom: 0;
                }
            }

            img {
                z-index: 101;
                height: 24px;
                width: 24px;
            }

            @include mobile {
                align-items: flex-start;
                padding: 0 16px;
            }
        }

        .color-bar {
            position: absolute;
            z-index: 99;
            width: 100%;
            height: 100%;
            animation: shrink 5s ease-in;
            animation-fill-mode: forwards;
            animation-duration: 0.5s;
            animation-delay: 1.25s;
            left: -0.25px;

            @include mobile {
                animation-duration: 0.6s;
                animation-delay: 2.25s;
            }
        }
    }

    .notification-banner-container-dismissible {
        @extend .notification-banner-container;
        position: absolute;
    }

    @keyframes dropdown {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
            transition: ease;
            opacity: 1;
        }
    }
    @keyframes closeOut {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%);
            transition: ease;
            opacity: 0;
        }
    }
    @keyframes shrink {
        from {
            height: -100%;
        }
        to {
            height: 8px;
        }
    }
    @keyframes textColorChanges {
        0% {
            color: white;
        }
        90% {
            color: $breadcrumb-blue;
        }
        100% {
            color: $breadcrumb-blue;
        }
    }
}

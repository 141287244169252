@import '../../../../styles/main.scss';

.brand-lincoln {
    .sidebar-container {
        font-family: $f1-font-light;
        background-color: $brand-white;
        display: flex;
        flex-direction: row;
        height: 100vh;
        position: absolute;
        overflow-y: scroll;
        top: 71px;
        width: 100%;
        z-index: 999;
        flex-wrap: nowrap;
        outline: none;

        .mobile-language-container {
            padding: 0.75rem;
            .toggle-text {
                display: inline;
                position: static;
            }
        }
        ul {
            margin: 0;
            list-style: none;
            padding-left: 0;
            border-bottom: 1px solid $white-smoke;
            &:last-child {
                border-bottom: 0;
            }

            li {
                list-style-type: none;
            }
        }

        .sidebar-menu-container {
            width: 100%;

            &.sidebar-minimized {
                width: 4rem;
            }

            li {
                margin-left: 0.5rem;

                &.unselected-sidebar-menu-item {
                    border-right: 2px solid #dfdfdf;
                }

                &.selected-sidebar-menu-item {
                    border: 2px solid #dfdfdf;
                    border-right: 0;
                    outline: none;
                }

                &.blue-search-bar {
                    height: 4.375rem;
                    background-color: #0276b3;
                    margin-left: 0;

                    .search-input-group {
                        ::placeholder {
                            color: white;
                        }
                        input {
                            padding-left: 1rem;
                            font-family: unset;
                            background-color: $hover-blue;
                            color: white;
                        }
                        .icon-search-glass-25px {
                            font-size: 2rem;
                            color: white;
                        }
                    }
                }
            }

            .sidebar-menu-item {
                font-family: $proxima-nova-regular;
                border-top: 1px solid $white-smoke;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                height: 4rem;
                line-height: 4rem;
                width: 100%;
                padding-left: 0.925rem;
                text-align: left;
                color: $brand-secondary;
                font-size: 1rem;
                outline: none;

                .sidebar-menu-item-text {
                    width: 100%;
                }

                .sidebar-menu-item-icon {
                    margin-right: 23px;
                    vertical-align: middle;
                    width: 18px;
                    height: 18px;
                }

                .chevron-icon-container {
                    flex-grow: 1;
                    margin-right: 2rem;
                    svg {
                        stroke: $brand-secondary;
                    }
                    .drawer-open-chevron {
                        transform: rotate(180deg);
                        float: right;
                    }
                }
            }
        }
    }
}

.brand-lincoln {
    .garage-section .heading-with-icon {
        font-size: 24px;
        letter-spacing: 2px;
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }

    .garage-section {
        @include tablet {
            padding-right: 25px;
        }

        .dropdown-label {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            font-size: 13px;
        }

        .dropdown {
            padding-bottom: 29px;

            @include mobile {
                padding-bottom: 36px;
            }
            .dropdown-button {
                background-color: $brand-while-smoke;
                height: 60px;
                padding: 0 14px 0;
            }

            .dropdown-container .dropdown-items-panel {
                &::-webkit-scrollbar {
                    background-color: $brand-scrollbar;
                    margin-right: 15px;
                    padding-bottom: 15px;
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background-color: $brand-mild-gray2;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $brand-scrollbar-thumb;
                }
                .dropdown-item {
                    color: $fds-color-black;
                    background-color: $brand-dropdown-list;

                    &.selected-item {
                        background-color: $brand-dropdown-selected;
                        &:before {
                            content: '\2714';
                            display: inline-block;
                            width: 20px;
                            margin-left: -15px;
                        }
                    }
                }
            }
        }
        button.primary-button {
            @include mobile {
                margin: auto;
            }
        }
    }
}

@import '../main.scss';
$nav-x-padding: 56px;
$nav-x-padding-mobile: pToR(20);

$onboarding-component-gap: 4rem;

@mixin onboarding-section-h3 {
    color: $f1-text-blue;
    font-family: F1Regular, sans-serif;
    font-style: normal;
    @include mobile {
        font-size: 28px;
        line-height: 32px;
    }
    @include fds-large {
        font-size: 36px;
        line-height: 44px;
    }
}
@mixin onboarding-section-title-text {
    @include mobile {
        font-size: 28px;
        line-height: 32px;
    }
    @include fds-medium {
        font-size: 44px;
        line-height: 50px;
    }
    @include fds-large {
        font-size: 54px;
        line-height: 64px;
    }

    color: $fds-color-primary;
    font-family: $f1-font-regular;
}

@mixin onboarding-body-text {
    font-size: 16px;
    line-height: 24px;
    color: $f1-text-blue;
}

@mixin onboarding-breadcrumbs {
    @include mobile {
        min-height: unset;
        & .breadcrumbs {
            padding-top: 16px;
            width: 90%;
            padding-right: 16px;
        }
        & .breadcrumbs-content {
            padding: 0;
            margin-left: $nav-x-padding-mobile !important;
        }
    }
    @include fds-large {
        min-height: 64px;
        & .breadcrumbs-content {
            padding: 0;
            margin-left: $nav-x-padding !important;
        }
    }
}

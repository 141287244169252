@import '../../../styles/onboarding/onboarding-globals.scss';
.brand-ford {
    .livestream-wrapper {
        .livestream-content-wrapper {
            .fm-card-fifty-fifty {
                margin: 56px 16px;
                @include desktop {
                    margin: 96px $nav-x-padding;
                }
            }
            .smash-card-gvt {
                margin-top: 0px;
            }
        }
        .onboarding-popular-video-tiles-container {
            margin: 0 $nav-x-padding-mobile;
            @include desktop {
                margin: 0 $nav-x-padding;
            }
        }
    }
}

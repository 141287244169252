.brand-lincoln {
    .login-section {
        h2,
        .login-desc {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        h2 {
            font-size: 24px;
            letter-spacing: 2px;
        }

        .login-desc {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}

.brand-lincoln {
    .enter-your-vin-section {
        @include desktop {
            max-width: 50%;
        }

        .heading-with-icon h2 {
            font-size: 24px;
            letter-spacing: 2px;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }

        .enter-your-vin-info-icon {
            content: url('../../../../../assets/info-red-icon.svg');
            width: pToR(19);
            height: pToR(19);
            margin-bottom: -2px;
        }
    }
}

@import '../../../../styles/lincoln/main.scss';

.lincoln-na-header {
    font-family: $proxima-nova-regular;
    font-size: 1.6rem;
    color: $brand-primary;
    box-sizing: border-box;
    z-index: 2001;
    min-height: 70px;

    a {
        text-decoration: none;
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        color: $brand-secondary;
    }

    button {
        margin: 0;
        border: 0 none;
        padding: 0;
        background-color: transparent;
        outline: 0;
    }

    .main-item-button {
        font-family: $proxima-nova-semi-bold;
        @include text(13, 3px, null, null, null);
        text-transform: uppercase;
    }

    .link-text {
        @include text(11, 1px, 13.42px, $brand-primary, null);
        position: relative;
        top: -3px;
    }

    .icon {
        @include text(16, null, null, $brand-primary, null);
    }

    .navigation-bar {
        z-index: 2001;
        position: inherit;
        width: 100%;
        border-bottom: 1px solid $brand-header-border;
    }

    .container {
        padding: 0 80px;
        background-color: $brand-white;

        .menu-items-wrap {
            display: flex;
            justify-content: space-between;
            min-height: 70px;

            .main-navigation-items {
                text-align: center;
                display: flex;
                padding: 0;
            }

            .navbar-content {
                margin: 0;
            }

            .left-nav-items {
                padding-bottom: 0;
            }
        }
    }

    .flyout-container {
        display: block;
        transform: translateY(-100%);
        visibility: hidden;
        position: absolute;
        transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
        top: 100%;
        left: 0;
        z-index: -2;
        width: 100%;
        background-color: $brand-white;
        border: 0 none;
        border-bottom: 1px solid $brand-header-border;
        border-top: 1px solid $brand-header-border;

        &.collapsed {
            display: none;
        }

        &.expanded {
            display: block;
            transform: translateY(0);
            visibility: visible;
        }

        .fgx-nav-icon {
            display: block;
            padding-right: 0;
            padding-bottom: 10px;
            font-size: pToR(30);
        }

        .enhanced-btn-img-bg {
            position: absolute;
            z-index: -1;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                min-width: 100%;
            }
        }

        .flyout-menu-item {
            max-height: 800px;
            overflow-y: auto;
        }

        .flyout-menu-item-content {
            padding: 72px 95px 65px 95px;

            .heading-container {
                padding-bottom: 60px;

                .flyout-menu-item-headline {
                    @include text(
                        36,
                        null,
                        null,
                        $brand-secondary,
                        $lincoln-millerb-roman
                    );
                    text-align: left;
                    font-weight: bold;
                    margin: 0;
                }
            }

            .column-wrap-container {
                display: flex;
                flex-flow: row nowrap;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                align-items: stretch;

                .column-item {
                    width: 100%;
                }

                .link-columns-wrap {
                    padding: 0 74px 0 74px;
                    flex: 0 1 35%;
                }

                .column-item {
                    display: block;
                    text-align: left;
                    padding-bottom: 45px;
                }

                .column-item-headline {
                    @include text(
                        13,
                        2px,
                        null,
                        $brand-primary,
                        $proxima-nova-regular
                    );
                    padding-bottom: 16px;
                    margin-bottom: 0;
                }

                .column-link-list {
                    list-style-type: none;
                    padding: 0;
                    margin-top: 0;
                    margin-bottom: 10px;
                }

                .column-link-item {
                    padding-bottom: 10px;
                }
            }

            .btn-icon-no-underline {
                @include text(18, null, 1.25, $brand-secondary, null);
                position: relative;
                max-width: 270px;
                background-color: transparent;
                border: 0;
                display: inline-block;
                white-space: normal;
                text-align: left;
                text-decoration: none;
                padding: 2px 0;

                .cta-text {
                    letter-spacing: 0.5px;
                    font-family: $proxima-nova-semi-bold;
                    text-transform: none;
                }
            }

            .btn-columns-wrap {
                flex: 0 1 65%;
                display: flex;
                flex-flow: row nowrap;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                align-items: flex-start;

                .column-btn-list {
                    margin-bottom: 20px;
                    margin-top: 0;
                    list-style-type: none;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    a:focus {
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                }

                .column-btn-item {
                    margin-top: 0;
                    margin-bottom: 20px;
                    display: block;
                    width: 100%;
                    flex: 0 50%;
                }

                .enhanced-btn {
                    min-height: 160px;
                    max-width: 95%;
                    margin-left: auto;
                    margin-right: auto;
                    overflow: hidden;
                    position: relative;
                    text-align: center;
                    background-color: $brand-while-smoke;
                    border-bottom: 1px solid $brand-link-water;
                    display: flex;
                    flex-flow: column nowrap;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }
                .enhanced-btn:hover,
                .enhanced-btn.has-bg-image:hover {
                    background-color: rgba(50, 64, 71, 0.9);
                    border-bottom: 2px solid $brand-border;
                    .enhanced-btn-content,
                    .icon {
                        color: $brand-white;
                    }
                }
                .enhanced-btn.has-bg-image {
                    color: $brand-white;
                    background-color: rgba(0, 0, 0, 0.4);
                }

                .enhanced-btn.has-bg-image {
                    .enhanced-btn-content {
                        color: $brand-white;
                    }
                }

                .enhanced-btn-content {
                    color: $brand-secondary;
                    padding: 15px;

                    .btn-headline {
                        @include text(
                            18,
                            0.5px,
                            null,
                            null,
                            $proxima-nova-semi-bold
                        );
                        margin: 0;
                    }
                }
            }
        }
    }

    .logo {
        display: none;
        .logo-link {
            float: left;
            padding: 15px 15px;
            @include text(18, null, 20px, null, null);

            &:focus {
                outline: 1px solid $brand-secondary;
            }

            .lincoln-logo {
                width: 139px;
                height: 36px;
                display: inline-block;
                position: relative;
                background-size: 139px 36px;
                background-position: 0;
            }
        }
    }

    .main-nav-item {
        display: inline-block;
        list-style: none;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        margin-left: 13px;
        margin-right: 13px;
        @include text(
            13,
            3px,
            15.99px,
            $brand-primary,
            $proxima-nova-semi-bold
        );

        .main-item {
            display: block;
            padding: 25px 2px 15px 2px;
            position: relative;

            .main-item-button {
                color: $brand-primary;
            }

            .iconl-chevron-toggle {
                display: inline-block !important;
                top: 2px;
                position: relative;
            }
        }

        .utility-item {
            box-sizing: border-box;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            position: relative;
            text-transform: none;
            display: block;
            padding: 25px 2px 15px 2px;
            margin: 0 13px 0 13px;
            height: 100%;

            &:hover,
            &:focus {
                .link-text {
                    color: $brand-secondary;
                    font-family: $proxima-nova-bold;
                }
            }
        }

        .login-container {
            padding: 25px 2px 15px 2px;
            margin: 0 13px 0 13px;
            height: 100%;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            position: relative;

            img {
                display: none;
            }

            i {
                display: inline-block;
            }

            &:hover,
            &:focus {
                .login-text {
                    color: $brand-secondary;
                    font-family: $proxima-nova-bold;
                }
            }

            .login-text {
                display: inline-block;
                @include text(11, 1px, 13.42px, $brand-primary, null);
                top: -3px;
            }
        }

        .search {
            &.navbar-form {
                display: block;
                padding: 20px 2px 15px 2px;
                margin: 0 13px 0 13px;
                height: 100%;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;
                position: relative;

                a {
                    color: $brand-primary;
                }

                &:hover,
                &:focus {
                    a {
                        color: $brand-secondary;
                        font-family: $proxima-nova-bold;
                    }
                }
            }
        }

        .language-toggle-container {
            position: relative;
            padding: 25px 2px 15px 2px;
            margin: 0 13px 0 13px;
            height: 100%;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;

            &:hover,
            &:focus {
                .toggle-text {
                    color: $brand-secondary;
                    font-family: $proxima-nova-bold;
                }
            }

            img {
                display: none;
            }

            .language-toggle {
                margin: 0;
                text-align: left;
                width: auto;
                height: auto;
            }

            .language-panel {
                top: 68px;
                right: -25px;

                ul {
                    padding: 0;
                }

                ul li {
                    padding: 0;
                }
            }

            ul li span {
                height: 20px;
                position: relative;
                display: block;
            }

            .language-option {
                padding: 15px 0 15px 0;
                @include text(
                    14,
                    3px,
                    null,
                    $brand-primary,
                    $proxima-nova-semi-bold
                );
                text-transform: uppercase;
            }

            .toggle-text {
                @include text(
                    11,
                    1px,
                    1.22,
                    $brand-primary,
                    $proxima-nova-bold
                );
                text-transform: uppercase;
            }
        }
    }

    .main-nav-item:hover,
    .main-nav-item:focus {
        border-top: 2px solid $brand-border;
        outline-offset: -1px;

        .main-item-button {
            color: $brand-secondary;
            font-family: $proxima-nova-bold;
            outline: none;
        }
    }

    .main-nav-item.flyout-open {
        outline: 1px solid $brand-secondary;
        border-top: 2px solid $brand-border;
    }

    &.flyout-opened .main-nav-item.flyout-open {
        .iconl-chevron-toggle:before {
            content: '\51';
            display: inline-block !important;
        }
    }

    @media screen and (max-width: $mobile-width) {
        .header-height {
            min-height: 50px;
        }
        .icon {
            font-size: pToR(20);
        }
        .container {
            padding-left: 15px;
            padding-right: 15px;

            .menu-items-container {
                padding-right: 15px;
                padding-left: 15px;
                border-top: 1px solid transparent;
                box-sizing: border-box;

                &.collapsed {
                    display: none;
                }

                &.expanded {
                    display: block;
                    position: fixed;
                    top: 51px;
                    left: 0;
                    z-index: 1001;
                    width: 100%;
                    height: 100%;
                    background-color: $brand-white;
                    overflow-x: hidden;
                    overflow-y: auto;
                    margin-left: 0;
                    margin-right: 0;
                }

                .menu-items-wrap {
                    display: flex;
                    flex-direction: column;

                    .main-navigation-items {
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                    }

                    .navbar-content {
                        margin: 7.5px -5px 7.5px -15px;
                    }

                    .left-nav-items {
                        padding-bottom: 30px;
                        padding-top: 22.5px;
                    }
                }
            }
        }
        .logo {
            display: flex;
            justify-content: space-between;

            .logo-link {
                padding: 0 15px 0 15px;

                .lincoln-logo {
                    top: 8px;
                }
            }

            .menu {
                transition: 0.2s;
                cursor: pointer;
                user-select: none;
                background: 0;
                outline: 0;
                position: relative;
                float: right;
                border-radius: 4px;
                margin: 13px 25px 8px 0;

                span,
                span:before,
                span:after {
                    height: 2px;
                }
            }
        }
        .main-nav-item {
            line-height: 1.23;
            border: none;
            margin-left: 0;
            margin-right: 0;
            font-family: $proxima-nova-bold;
            .main-item.padding {
                padding-bottom: 42px;
            }
            .main-item {
                display: block;
                padding: 20px 15px 20px 15px;
                margin-left: 0;
                margin-right: 0;
                border: none;

                .main-item-button {
                    @include text(18, null, null, $brand-secondary, null);
                    float: left;
                }

                .main-item-button.selected-item,
                .main-item-button:hover,
                .main-item-button:focus {
                    border-bottom: 1px solid $brand-border;
                }
            }
            button.main-item {
                width: 100%;
            }

            .main-item i {
                float: right;
            }

            .main-item .iconl-chevron-toggle {
                display: none !important;
            }

            .utility-item {
                letter-spacing: 1px;
                font-family: $proxima-nova-semi-bold;
                margin: 0;
                padding: 20px 15px 20px 15px;

                &:hover,
                &:focus {
                    border-top: 2px solid transparent;
                }
            }

            .login-container {
                padding: 20px 15px 20px 15px;
                margin: 0;
                right: 0;

                &:hover,
                &:focus {
                    border-top: 2px solid transparent;
                }
            }

            &.search-item {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .search {
                &.navbar-form {
                    height: 60px;
                    margin: 0;
                    border-top: 0 none;
                    border-bottom: 0 none;
                    padding: 0;
                    box-shadow: none;
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        border-top: 2px solid transparent;
                    }

                    form {
                        height: 100%;
                    }

                    .form-group {
                        height: inherit;
                        margin-bottom: 0;

                        .form-wrapper {
                            height: 100%;
                        }
                    }

                    input[type='search'] {
                        @include text(
                            18,
                            null,
                            null,
                            $brand-primary,
                            $proxima-nova-semi-bold
                        );
                        position: relative;
                        z-index: 5;
                        width: 98%;
                        height: inherit;
                        margin: 0;
                        border: 0 none;
                        padding: 0 20px 0 43px;
                        background: transparent none;
                        outline: 0;
                    }

                    button {
                        position: absolute;
                        appearance: none;
                        right: auto;
                        left: 15px;
                        z-index: 10;
                        width: 25px;
                        height: 60px;
                        background-color: transparent;
                        color: $brand-primary;
                    }
                }
            }
        }
        .main-nav-item:hover,
        .main-nav-item:focus {
            border-top: 2px solid transparent;
        }
        .main-nav-item.language-item {
            text-align: right;
            padding: 0 30px 15px 15px;
        }

        .main-nav-item .language-toggle-container {
            position: relative;
            padding: 0;

            &:hover,
            &:focus {
                border-top: 2px solid transparent;
            }

            .language-panel {
                top: 39px;
                right: 0;
                width: 95%;
            }

            .toggle-text {
                display: block;
                top: 0;
                left: 0;
                position: relative;
                @include text(14, 3px, 1.22, null, null);
            }
        }
        .main-nav-item.flyout-open {
            outline: 1px solid $brand-secondary;
            border-top: none;
            outline-offset: -1px;

            .main-item-button {
                border-bottom: 1px solid $brand-border;
            }
        }
        &.flyout-opened .container .menu-items-container .menu-items-wrap {
            display: none;
        }
        &.flyout-opened .container .menu-items-container {
            top: 0;
        }

        .flyout-container {
            top: 0;
            border-bottom: 0;
            border-top: 0;
            padding-bottom: 51px;

            .fgx-nav-icon {
                display: inline-block;
                padding-bottom: 0;
                padding-right: 8px;
            }

            .flyout-header {
                border-bottom: 1px solid #979797;
                min-height: 50px;
                display: flex !important;
                flex-flow: row nowrap;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                align-items: center;
                padding: 0 25px 0 15px;

                .flyout-header-item {
                    flex: 0 1 auto;
                    font-size: 30px;

                    .icon {
                        font-size: 30px;
                    }

                    .back-arrow {
                        display: inline-block;
                        transform: rotate(180deg);
                    }
                }
            }

            .flyout-menu-item-content {
                padding: 25px 0 0 0;

                .heading-container {
                    padding: 0 25px 16px 25px;

                    .flyout-menu-item-headline {
                        font-size: pToR(18);
                        text-align: center;
                    }
                }

                .column-wrap-container {
                    flex-direction: column;

                    .column-item {
                        width: 100%;
                        padding-bottom: 55px;
                    }

                    .link-columns-wrap {
                        padding: 20px 40px;
                    }

                    .column-link-item {
                        padding-bottom: 10px;
                    }
                }

                .btn-icon-no-underline .cta-text {
                    padding: 0;
                }

                .btn-columns-wrap {
                    flex: none;
                    flex-direction: column;

                    .column-item {
                        width: 100%;
                        padding-bottom: 0;
                    }

                    .column-btn-item {
                        margin-bottom: 0;
                        flex: none;
                    }

                    .enhanced-btn {
                        min-height: 90px;
                        max-width: 100%;
                    }

                    .enhanced-btn-content .btn-headline {
                        display: inline-block;
                        margin-bottom: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .visible-xs {
        @include desktop {
            display: none;
        }
    }
}

@import '../../../styles/main.scss';
.brand-ford {
    .how-to-banner {
        .how-to-top {
            background-position: center;
            background-size: cover;

            color: white;
            font-family: $f1-font-regular;
            height: 350px;
            width: 100%;
            margin: auto;
            @include desktop {
                height: 386px;
                width: 100%;
                margin: 0;
            }
            .how-to-cta {
                text-align: center;
                margin: 0;

                @include desktop {
                    max-width: 100%;
                    margin: 0 pToR(18);
                }
            }
        }

        .how-to-bottom-container {
            position: relative;
            width: 100%;
            margin: auto;
            @include desktop {
                width: 100%;
                height: 64px;
            }
            .how-to-bottom {
                position: relative;
                display: flex;
                justify-content: center;
                margin-top: -45px;
            }
        }
    }
}

.popover-enter-your-vin-tooltip {
    .popover-container:before {
        left: 298px !important;
        top: 128px;
    }
}
.brand-lincoln {
    .maintenance-schedule-landing-page {
        margin: 5% 0;

        .maintenance-schedule-landing-heading-section,
        .back-to-ford-account-link,
        .maintenance-error {
            margin-left: 7%;
            margin-right: 7%;
        }

        .enter-your-vin-combined-wrapper {
            background-color: $brand-gray1;
            border: 1px solid $brand-gray3;
            box-shadow: none;

            @include desktop {
                margin-right: 5%;
                margin-left: 5%;
            }

            @include mobile {
                padding-right: 5%;
                padding-left: 5%;
            }
        }

        .maintenance-schedule-landing-heading-section {
            .maintenance-schedule-landing-header,
            .maintenance-schedule-landing-description p {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }

            .maintenance-schedule-landing-header {
                font-size: 32px;
                letter-spacing: 2px;
            }

            .maintenance-schedule-landing-description p {
                font-size: 18px;
                letter-spacing: 1px;
            }
        }

        .enter-your-vin-combined-wrapper .vehicle-details-wrapper {
            .divider-section {
                margin-right: 30px;

                span {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }

                hr.before-dash,
                hr.after-dash {
                    top: 11px;
                }
            }
            .enter-your-vin-section {
                .heading-with-icon h4 {
                    display: inline;
                }
                .enter-your-vin-info-wrapper {
                    display: inline;
                }
            }
            div.vehicle-details-form-left {
                padding-right: 0;
            }
        }

        .maintenance-error {
            .error-message p {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                font-size: 18px;
            }
        }
    }
}

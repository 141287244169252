.brand-lincoln {
    .home-sign-in-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        .primary-button {
            font-family: $proxima-nova-semi-bold;
        }
    }

    .second-signin-button {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }
}

.brand-lincoln {
    .support-fds-wrapper {
        .notification-banner p {
            font-size: 16px;
        }
    }
    .notification-banner-container {
        opacity: 0;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        animation-name: dropdown;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        animation-timing-function: linear;
        z-index: 1000;
        margin-top: 0;

        @include mobile {
            animation-delay: 2s;
            animation-duration: 0.6s;
        }
        .notification-banner {
            padding: 0 32px;
            display: flex;
            min-height: 40px;
            justify-content: space-between;
            align-items: center;
            border-bottom: none;
            position: relative;
            background-color: #324047;
            z-index: 1999;
            &.warning {
                background-color: #fcded8;
            }
            &.success {
                background-color: #fcded8;
            }
            div > span {
                padding-right: 10px;
            }
            #warning .forward-icon {
                &::before {
                    color: $brand-secondary;
                }
            }

            img {
                height: 24px;
                padding-right: 10px;
            }

            .forward-icon {
                height: 20px;
                padding: 10px 5px 0 0;
                &::before {
                    color: #ffffff;
                }
            }

            #warning > p {
                color: $brand-secondary;
            }

            #warning a {
                color: $brand-secondary;
                :hover {
                    text-decoration: underline;
                }
            }

            #default > p {
                color: #ffffff;
            }

            p,
            a {
                display: inline;
                font-family: $proxima-nova-regular;
                line-height: 26px;
                text-decoration: none;
                font-size: 16px;
                color: #ffffff;
            }

            a {
                font-family: $proxima-nova-semi-bold;
                padding-right: 4px;
                &:hover {
                    text-decoration: underline;
                }
            }

            > div {
                display: flex;
                width: 100%;
                justify-content: center;

                span {
                    margin-left: 0;
                }
                a {
                    display: inline-flex;
                    @include desktop {
                        padding-left: 20px;
                    }
                }
            }
            div {
                span {
                    margin-bottom: 0;
                }
            }
            button {
                border: none;
                background-color: transparent;
                height: 13px;
            }
            .notification-wrapper {
                position: relative;
                align-items: center;
                display: flex;
                padding-right: 10px;
                height: 100%;
                z-index: 101;

                .notification-content {
                    position: relative;
                    margin-left: 15px;
                    z-index: 101;
                    width: 100%;
                    @include mobile {
                        display: inline;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 24px;
                    }
                    .chevron-icon {
                        position: relative;
                        @include mobile {
                            top: 5px;
                        }
                        &::before {
                            font-family: FdsFordIcons, sans-serif;
                            content: $fds-font--ford-icons__play-arrow;
                            color: $brand-secondary;
                            font-size: 15px;
                            position: relative;
                            @include mobile {
                                bottom: 2px;
                            }
                        }
                    }
                }

                a {
                    display: inline-flex;
                }
            }

            .close-icon {
                height: 13px;
                padding-right: 20px;
                cursor: pointer;
            }

            @include mobile {
                align-items: flex-start;
                padding: 5px 16px 10px 16px;
                button {
                    height: 24px;
                    width: 24px;
                    padding-top: 5px;
                }
            }
        }
    }
    .notification-banner-container-dismissible {
        @extend .notification-banner-container;
        position: absolute;
    }
}

.brand-ford {
    .garage-section {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;

        .heading-with-icon {
            display: inline;
            font-size: 20px;
            line-height: 1.5;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
            padding-bottom: 17px;

            @include mobile {
                padding-bottom: 33px;
            }
        }

        .dropdown-label {
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;

            @include mobile {
                float: left;
                padding-bottom: 15px;
            }
        }

        .dropdown.garage-dropdown-section {
            padding: 11px 0 29px 0;

            @include mobile {
                padding-bottom: 20px;
            }

            &.dropdown-error .dropdown-button {
                border-color: $error-text;
            }

            .dropdown-button {
                background-color: $fds-color-white;
                border-color: $fds-color-md-gray;
                height: 50px;
            }

            .dropdown-items-panel {
                margin-top: 12px;

                @include mobile {
                    margin-top: 27px;
                }
            }
        }

        button.primary-button {
            @include mobile {
                margin: auto;
            }
        }

        .error-icon {
            height: 16px;
            width: 16px;
            top: 3px;
            left: 5px;
            position: relative;
            margin: 0;

            @include mobile {
                float: left;
                top: -3px;
                position: relative;
            }
        }

        .error-message {
            top: -32px;
            position: relative;
            color: $error-text;
            line-height: 21px;

            @include mobile {
                padding: 0;
                top: -22px;
                white-space: normal;
                text-align: left;
            }
        }
    }
}

@import '../../../../../styles/main.scss';

.enter-your-vin-section {
    display: inline-block;
    flex: 1;

    @include mobile {
        width: 100%;
        text-align: center;
        padding-left: 0;

        input {
            margin-top: 20px;

            &:placeholder-shown {
                text-overflow: ellipsis;
            }
        }

        .heading-with-icon {
            margin-top: 10px;
        }
    }

    .input-field-container {
        max-width: 460px;
    }

    label {
        margin-top: 23px;
        margin-bottom: 13px;
        display: flex;
        padding: 0 0 15px 0;

        @include mobile {
            display: none;
        }

        .error-icon {
            position: relative;
            top: -3px;
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }

    .heading-with-icon {
        display: inline;
        font-size: 20px;
        line-height: 1.5;
        font-family: $f1-font-light;
        color: $fds-color-dk-gray;

        h2 {
            display: inline;
        }

        @include mobile {
            h4,
            h2 {
                margin-bottom: 4px;
            }
        }
    }

    .vin-textField,
    .mileage-textField {
        width: 100%;
        box-sizing: border-box;
        height: 50px;
        border: 1px solid $fds-color-md-gray;
        border-radius: 3px;
    }

    .vin-textField {
        margin-bottom: 2px;
    }

    .input-field-error {
        border: 1px solid $error-message-text;
        max-width: 460px;
    }

    .error-message {
        font-family: $f1-font-regular;
        font-size: 12px;
        line-height: 21px;
        color: $error-message-text;
        margin-top: 2px;
        white-space: normal;
        @include mobile {
            padding: 0;
            text-align: left;
        }
    }
    .input-field {
        max-width: 460px;
    }

    .enter-your-vehicle-cta-wrapper {
        @include mobile {
            display: flex;
            justify-content: center;
        }
        .enter-your-vehicle-cta {
            margin-top: 29px;

            @include mobile {
                margin-top: 36px;
            }
        }
    }
    .enter-your-vin-info-wrapper {
        margin-left: pToR(12);
        background: none;
        cursor: pointer;
        border: none;

        .enter-your-vin-info-icon {
            content: url('../../../../../assets/info-icon.svg');
            width: pToR(18);
            height: pToR(18);
            margin-bottom: -2px;
        }
        @include mobile {
            margin-top: pToR(13);
            margin-left: pToR(10);
            justify-content: center;
        }
    }
}

.popover-enter-your-vin-tooltip {
    @include tablet {
        background-color: transparent;
    }
    .popover-container {
        @include mobile {
            padding-right: 4px;
        }
        .popover-content {
            &.enter-your-vin-tooltip {
                @include mobile {
                    margin-right: 25px;
                }
                @include tablet {
                    min-height: 120px;
                    width: 270px;
                    max-height: 350px;
                }
                p {
                    padding-right: 0px;
                    @include mobile {
                        line-height: 1.63;
                        padding-right: 25px;
                    }
                }
            }
        }

        &:before {
            top: 128px;
            @include mobile {
                display: none;
            }
        }
        &:after {
            top: 129px;
            content: '';
            width: 11px;
            position: absolute;
            @include mobile {
                display: none;
            }
        }
    }
}
.brand-ford .enter-your-vin-section {
    @include tablet {
        div.input-field-container.fds-style {
            margin-top: 11px;
        }
    }
    .enter-your-vehicle-cta-wrapper {
        margin-top: 50px;

        @include mobile {
            margin-top: 25px;
        }
    }
}

.popover-enter-your-vin-tooltip.popover-left-arrow {
    .popover-container {
        &:before {
            left: -21px;
            border-top: 11px solid transparent;
            border-left: 10px solid $grey;
            border-bottom: 11px solid transparent;
            border-right: none;
            transform: rotate(180deg);
        }
        &:after {
            left: -20px;
            position: absolute;
            border-top: 10px solid transparent;
            border-right: none;
            border-bottom: 10px solid transparent;
            border-left: 10px solid $white;
            transform: rotate(180deg);
        }
    }
}
.popover-enter-your-vin-tooltip.popover-right-arrow {
    .popover-container {
        &:before {
            left: 298px;
            border-top: 11px solid transparent;
            border-right: 10px solid $grey;
            border-bottom: 11px solid transparent;
            border-left: none;
            transform: rotate(180deg);
        }
        &:after {
            left: 297px;
            position: absolute;
            border-top: 10px solid transparent;
            border-left: none;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $white;
            transform: rotate(180deg);
        }
    }
}

@import './enter-your-vin-section.lincoln';

.brand-lincoln {
    .header {
        display: flex;
        gap: 1px;
        align-items: center;
        flex-direction: row;
        padding: 0 2rem;
        position: sticky;
        width: 100%;
        z-index: 2001;
        top: 0;
        background: $fds-color-white;
        height: 5.25rem;

        @include tablet {
            position: sticky;
        }

        @include desktop {
            position: relative;
        }

        li {
            list-style: none;
        }
        .hide-nav {
            display: none;
        }

        nav {
            @include desktop {
                padding: 10px;
            }
        }

        .desktop-subnav {
            position: absolute;
            top: 5.25rem;
            left: 0;
            margin-right: 0.5rem;
            width: 100%;
            background: $brand-white;
            transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);

            .sidebar-img-subnav {
                flex-wrap: wrap;
                margin-top: 5px;
                margin-bottom: 4rem;
                justify-content: center;
                padding: 50px 0;
                .subnav-image-containers {
                    display: inline-flex;
                    flex-direction: row;
                    text-align: center;
                    margin-bottom: 20px;
                    .subnav-image-container {
                        padding: 0 7px;
                        &.border-right {
                            border-right: 1px solid #d9d9d9;
                        }
                        .subnav-image {
                            width: 150px;
                        }
                        .category-title {
                            font-family: $lincoln-millerb-semibold;
                            font-size: 20px;
                            line-height: 1.1;
                            color: $brand-secondary;
                        }
                        .title {
                            font-size: 14px;
                            font-family: $proxima-nova-regular;
                            color: $brand-secondary;
                        }
                        .description,
                        .price {
                            font-size: 20px;
                            font-family: $lincoln-millerb-semibold;
                            color: $brand-secondary;
                        }
                        .link {
                            a {
                                font-size: 14px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                &:hover {
                                    color: $brand-border;
                                }
                            }
                        }
                    }
                }
            }

            .sidebar-subnav {
                flex-wrap: wrap;
                padding-left: 3rem;
                padding-right: 4rem;
                border-top: 1px solid $white-smoke;
                margin-top: 5px;
                margin-bottom: 4rem;
                .subnav-title-container {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 3rem;
                    margin: 0 1rem 0 0;
                    .drawer-title {
                        display: inline-block;
                        font-size: 1.5rem;
                        font-family: $lincoln-millerb-semibold;
                        color: $brand-secondary;
                        padding-right: 1rem;
                        padding-bottom: 0.9375rem;
                        width: 80%;
                        text-align: left;
                        white-space: nowrap;
                    }
                    .subnav-link-container {
                        padding-top: 1rem;
                        display: flex;
                        border-top: 1px solid lightgray;
                        ul {
                            padding-right: 3rem;
                            li {
                                padding: 0.1875rem 0;
                                a {
                                    font-family: $proxima-nova-regular;
                                    color: $brand-secondary;
                                    font-size: 16px;
                                    border: none;
                                    &:hover {
                                        color: $brand-border;
                                    }
                                }
                            }
                        }
                    }
                }
                .subnav-link {
                    color: $hover-blue;
                    font-family: $f1-font-light;
                    font-size: 0.875rem;
                    padding-top: 0.625rem;
                    padding-right: 0.625rem;
                    padding-bottom: 0.625rem;
                    a {
                        padding: 0;
                        border: none;
                        border-bottom: 2px solid transparent;
                    }
                    a.selected-item,
                    a:hover,
                    a:focus {
                        border-color: $brand-border;
                        cursor: pointer;
                    }
                }
            }
        }

        .visible-in-mobile {
            display: none;
        }
        .first-header-link {
            &.slected-item,
            &:focus,
            &:hover {
                outline: 1px solid $hover-blue;
            }
        }
        .logo-link {
            padding: 0 0.8rem;

            .ford-logo {
                background: transparent no-repeat -200px 0;
                background-size: 25rem 12.5rem;
                display: inline-block;
                padding: 0;
            }
        }

        a,
        button {
            background-color: $brand-white;
            text-decoration: none;
            position: relative;
            font-family: $lincoln-millerb-semibold;
            font-size: 1rem;
            color: $brand-primary;
            cursor: pointer;

            &.desktop {
                padding: 1rem 0.3rem;
                box-sizing: border-box;
                transition: 0.3s;
                border: none;
                border-bottom: 2px solid transparent;

                &:hover,
                &:active,
                &:focus {
                    border-color: $brand-border;
                    cursor: pointer;
                    font-family: $proxima-nova-bold;
                    color: $brand-secondary;
                }

                &.active {
                    border-color: $brand-border;
                    cursor: pointer;
                    font-family: $proxima-nova-bold;
                }

                .icon {
                    margin-left: 6px;
                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }

            &.locate-dealer {
                display: inline-block;
                height: 100%;
                border: none;
                border-bottom: 2px solid transparent;
                &.selected-item,
                &:focus,
                &:hover {
                    border-color: $brand-border;
                }

                img {
                    margin-right: 15px;
                    vertical-align: middle;
                    display: inline-flex;
                }

                span {
                    @media screen and (max-width: $small-desktop-width) {
                        display: none;
                    }
                }
            }

            sup {
                vertical-align: baseline;
                position: absolute;
                top: -15px;
                font-size: 10px;
                right: -2px;
            }

            &.login {
                margin-left: 1rem;
                top: -1px;
            }
        }

        button {
            border: none;
            background-color: unset;
        }

        .right-align {
            margin-left: auto;
        }

        @media screen and (max-width: $mobile-width) {
            height: 70px;
            padding: 0;

            .desktop,
            .desktop-subnav {
                display: none;
            }

            .visible-in-mobile {
                display: block;
            }

            .logo-link,
            .mobile-fill,
            .menu {
                flex: 1 1 calc(100% / 3);
                outline: none;
            }

            .logo-link {
                height: unset;
                padding: 0;
                position: absolute;
            }

            .menu {
                text-align: left;

                span {
                    &,
                    &::before,
                    &::after {
                        border-radius: 0;
                        height: 2px;
                    }
                }
            }

            a.logo-link {
                margin: 0;
                left: 40%;
                width: 20%;
                text-align: center;
            }
        }
        .language-toggle {
            &:hover,
            &:focus,
            &.selected-item {
                outline: 1px solid $hover-blue;
            }
        }
        button {
            &:hover,
            &:focus,
            &.selected-item {
                border-color: $brand-border;
            }
        }
        .main-nav-item {
            display: flex;
        }
        .main-nav-items {
            display: inline-block;
            list-style: none;
            font-size: pToR(13);
            letter-spacing: 3px;
            line-height: 15.99px;
            padding: 20px;
        }
        .drawer-title {
            display: inline-block;
            font-family: $f1-font-light;
            width: 80%;
            text-align: left;
            padding-right: 30px;
            padding-bottom: 15px;
            white-space: nowrap;
            font-size: 24px;
            color: #555;
            line-height: 1.1;
        }
    }

    .background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
        opacity: 0.7;
        background: #000 none;
    }
}

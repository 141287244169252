.brand-ford {
    .hero-tile {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border: 0.125rem solid $white;
        box-sizing: border-box;
        border-radius: 0.188rem;
        box-shadow: $tile-drop-shadow;
        transition: 0.5s;
        text-decoration: none;
        padding-top: 1.25rem;
        align-items: center;
        text-align: left;
        height: 9.375rem;
        width: 100%;
        vertical-align: middle;

        line-height: 1.875;

        font-family: $f1-font-regular;

        @include tablet {
            margin: 0;
            display: inline-flex;
            text-align: center;
        }

        &:hover {
            transform: scale(1.07);
            box-shadow: -0.125rem -0.25rem 0.938rem rgba($box-shadow-gray, 0.25),
                0.125rem 0.25rem 0.938rem rgba($box-shadow-gray, 0.25);
            .content {
                color: $dark-blue;
            }
        }

        .tile-icon {
            margin-top: 1rem;
            height: 2rem;
            width: 2rem;
            color: #00095b;
        }

        .content {
            color: $text-gray;
            font-size: 1.25rem;
            text-transform: uppercase;

            @include tablet {
                margin: 0.688rem 0 1.25rem;
            }
        }

        .beta-superscript {
            font-style: italic;
            text-transform: lowercase;
            font-size: 0.875rem;
            line-height: 1.875rem;
        }
    }
}

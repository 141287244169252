@import '../../../../styles/main.scss';

.brand-lincoln {
    .maintenance-carousel {
        .carousel-heading,
        .toggle-subheading {
            @include mobile {
                text-align: center;
            }
        }

        .toggle-subheading {
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 29px;
            letter-spacing: normal;

            @include mobile {
                margin-top: 25px;
                margin-bottom: 20px;
            }

            @media (max-width: 320px) {
                margin-top: 15px;
            }
        }

        .toggle-description {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: normal;
            color: $dyf-color-darkgrey;

            &.eu-region {
                margin-bottom: 30px;
            }

            .description-wrapper {
                margin: 0 25px;
                list-style-position: inside;

                @include mobile {
                    margin-left: 50px;
                }

                .maintenance-list {
                    text-align: start;
                    list-style-type: none;
                    position: relative;

                    &::before {
                        content: '\2022';
                        position: absolute;
                        left: -0.8em;
                        font-size: 1.1em;
                    }

                    span {
                        position: relative;
                        left: 10px;
                    }
                }
            }

            .supplementary-description {
                margin: 40px 21px 70px 0;

                @include mobile {
                    margin: 40px 25px 40px;
                }

                a {
                    color: $brand-secondary;
                    font-family: $proxima-nova-semi-bold;
                    text-decoration: none;
                    border-bottom: 2px solid $brand-border;
                    letter-spacing: normal;

                    &:hover,
                    &:focus {
                        color: $brand-secondary;
                        border-bottom: 2px solid $brand-secondary;
                    }
                }
            }
        }

        .carousel-header-wrapper {
            margin: 20px 0 48px 0;

            @include mobile {
                margin: 20px 20px 0 20px;
            }
        }

        .dash-content-slider {
            position: relative;

            .dash-content-slider-container {
                position: relative;
                overflow: hidden;
                margin: 0 1px;
                padding-bottom: 20px;

                @include mobile {
                    padding-bottom: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    width: 100%;
                    border-bottom: 1px solid $brand-gray3;

                    @include mobile {
                        display: none;
                    }
                }

                div.dash-content-slider-wrapper {
                    display: flex;
                    position: relative;
                    margin: 0;
                    padding: 0;
                    button.dash-content-slider-list-item {
                        min-width: 100px;
                        list-style: none;
                        float: left;
                        text-align: center;
                        display: block;
                        padding: 6px 15px;
                        position: relative;
                        cursor: pointer;
                        color: $brand-secondary;
                        border: none;
                        background-color: #fff;

                        @include mobile {
                            padding: 0 10px;
                        }

                        .odometer-icon {
                            width: 24px;
                            height: 24px;
                            content: url(../../../../assets/odometer-active.svg);
                        }

                        .odometer-dis-icon {
                            width: 24px;
                            height: 24px;
                            content: url(../../../../assets/odometer-disabled.svg);
                        }

                        .odometer-text {
                            font-family: $proxima-nova-semi-bold;
                            font-size: 16px;
                            letter-spacing: normal;
                            line-height: 26px;
                            text-align: center;
                            color: inherit;
                            word-break: break-word;

                            @include desktop {
                                padding: 0 20px;
                            }

                            @include mobile {
                                padding: 0 15px;
                            }
                        }

                        &.active {
                            color: $brand-secondary;

                            &::after {
                                background: $brand-border;
                                z-index: 1;
                                bottom: -19px;
                                content: '';
                                position: absolute;
                                left: 0;
                                height: 3px;
                                width: 100%;
                                display: inline-block;

                                @include mobile {
                                    display: none;
                                }
                            }

                            &::before {
                                @include mobile {
                                    content: '';
                                    position: absolute;
                                    display: inline-block;
                                    background: $brand-mild-gray1;
                                    height: 30px;
                                    width: 0.5px;
                                    right: 0;
                                    top: 10px;
                                    border-left: 0.5px solid $brand-mild-gray1;
                                }
                            }
                        }
                    }

                    button:nth-last-child(2) {
                        &::before {
                            @include mobile {
                                content: '';
                                position: absolute;
                                display: inline-block;
                                background: $brand-mild-gray1;
                                height: 30px;
                                width: 0.5px;
                                right: 0;
                                top: 10px;
                                border-left: 0.5px solid $brand-mild-gray1;
                            }
                        }
                    }
                }
            }

            .dash-slider-btn {
                color: $brand-primary;
                cursor: pointer;
                display: inline-block;
                position: absolute;
                top: calc(50% - 20px);

                &:after {
                    font-size: 24px;
                    display: inline-block;
                    position: relative;
                    top: 7px;
                }

                .fds-chevron {
                    .fds-dot {
                        visibility: hidden;
                    }

                    .fds-arrow {
                        font-size: 24px;
                        font-weight: 500;
                        margin-left: -20px;
                        color: $brand-secondary;
                    }
                }

                &.disabled {
                    color: $brand-mild-gray1;
                    cursor: default;

                    .fds-chevron {
                        .fds-arrow {
                            color: $brand-dark-grey;

                            @include mobile {
                                visibility: hidden;
                            }
                        }
                    }
                }

                &.next {
                    right: -20px;
                    font-weight: 400;
                    line-height: 0.95rem;
                    font-size: 1rem;

                    @include mobile {
                        right: 15px;
                        top: 15px;
                    }

                    @include mobile-landscape {
                        top: 20px;
                    }

                    &:after {
                        left: 9px;
                        transform: rotate(270deg);
                    }
                }

                &.previous {
                    left: -20px;
                    line-height: 0.95rem;

                    @include mobile {
                        left: 15px;
                        top: 18px;
                        z-index: 3;
                    }

                    @include mobile-landscape {
                        top: 25px;
                    }

                    &:after {
                        left: 5px;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}

@import '../../../../styles/main.scss';

.fm-maintenance-schedule-header {
    margin: 64px 16px 0 16px;
    @include desktop {
        margin: 96px 16px 0 56px;
    }
    .fm-maintenance-schedule__title {
        font-size: 40px;
        line-height: 48px;
        color: $fm-text-grey;
    }
    .fm-maintenance-schedule__subtitle {
        margin: 16px 16px 0 0;
        font-size: 16px;
        line-height: 24px;
        color: $f1-text-blue;
    }
}
.fm-carousel__container {
    margin-top: 0;
}
.maintenance-schedule-landing-page {
    margin: 5% 7%;
    font-family: $f1-font-regular;
    color: $fds-color-dk-gray;

    .maintenance-schedule-landing-header {
        font-size: 34px;
        line-height: 1.29;
        font-family: $f1-font-light;
        margin-bottom: 16px;

        @include mobile {
            text-align: center;
        }
    }

    .maintenance-error {
        margin-bottom: 10%;

        @include mobile {
            margin-right: 5px;
            margin-left: 5px;
        }

        .error-message {
            @include mobile {
                padding-left: 0px;
                padding-right: 0px;
            }
            p {
                @include mobile {
                    white-space: normal;
                }
            }
        }
    }

    .maintenance-schedule-landing-description {
        font-size: 16px;
        line-height: 1.63;
    }

    .enter-your-vin-combined-wrapper {
        margin-top: 45px;
        margin-bottom: 70px;
        padding: 51px 56px 41px 53px;
        box-shadow: 0 12px 10px 5px rgba(0, 0, 0, 0.12);

        @include mobile {
            box-shadow: none;
            padding: 39px 0 41px 0;
            margin-top: 35px;
            margin-bottom: 42px;
            border-top: 1px solid $fds-color-overlay;
        }

        .vehicle-details-wrapper {
            display: flex;
            flex-wrap: wrap;

            @include mobile {
                flex-direction: column;
            }

            .vehicle-details-form-left {
                display: inline-block;
                padding: 3px 50px 0 0;
                flex: 1 1;

                @include mobile {
                    width: 100%;
                    padding: 0;
                    text-align: center;
                }
            }

            .vehicle-details-form-left-hide {
                display: none;
            }

            .vehicle-details-form-left .login-section .login-btn-wrap {
                display: flex;

                @include mobile {
                    align-items: center;
                    flex-direction: column;
                    padding: 0 40px;
                }
                .primary-button {
                    @include desktop {
                        margin-right: 32px;
                    }
                }
            }

            .divider-section {
                display: block;
                width: auto;
                position: relative;
                color: $fds-color-dk-gray;
                align-self: center;
                margin: 45px 68px 40px auto;

                @include mobile {
                    margin: 0 68px 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }

                &.divider-logged-in {
                    margin-bottom: 85px;

                    @include mobile {
                        margin: 44px 0 28px 0;
                    }
                }

                span {
                    padding-left: 30px;

                    @include mobile {
                        padding: 15px;
                    }
                }

                hr {
                    position: absolute;
                    width: 18px;
                    border: 0.1px solid $fds-color-dk-gray;
                    box-shadow: none;

                    @include mobile {
                        position: unset;
                        margin: 0;
                    }

                    &.before-dash {
                        top: 9px;
                        display: inline-block;
                    }

                    &.after-dash {
                        margin-left: 9px;
                        top: 9px;
                        display: inline-block;

                        @include mobile {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}
.popover-enter-your-vin-tooltip {
    .popover-container:before {
        left: 298px !important;
        top: 128px;
    }
}
.brand-ford .maintenance-schedule-landing-page .enter-your-vin-section {
    .heading-with-icon h2 {
        font-size: 20px;
        @include tablet {
            margin-top: 5px;
            margin-bottom: 18px;
        }
    }
    .enter-your-vin-info-wrapper {
        display: inline;
    }
    div.input-field-container:nth-last-child(2) {
        margin-top: 40px;
    }
}
.support-section {
    article.feature-trio {
        margin: 0 7% !important;
    }
}

@import './maintenance-schedule-landing.lincoln.scss';

.brand-lincoln {
    .hero-tile {
        display: flex;
        flex-direction: row;
        background-color: $white;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        transition: 0.5s;
        text-decoration: none;
        padding: 9px 0;
        align-items: center;
        justify-content: center;
        text-align: left;
        width: 100%;
        vertical-align: middle;
        letter-spacing: 3px;
        line-height: 1.875;

        font-family: $proxima-nova-regular;

        @include tablet {
            margin: 0;
            display: inline-flex;
            text-align: center;
            max-width: 220px;
        }

        @include md-desktop {
            max-width: 270px;
        }

        &:hover {
            border-bottom: 3px solid $brand-border;
            transform: unset;
        }

        .tile-icon {
            display: none;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $brand-secondary;
            font-family: $proxima-nova-semi-bold;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 30px;
            @include tablet {
                margin: 0;
            }
        }
        .search-icon {
            position: relative;
            margin-left: 12px;
            bottom: -1px;
        }
    }
}

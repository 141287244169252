@import '../../../styles/main.scss';

.language-toggle-container {
    color: $black;
    font-family: inherit;
    font-weight: inherit;
    margin-right: 2rem;

    @include desktop {
        margin-right: unset;
    }

    .language-toggle {
        display: inline-block;
        position: relative;
        height: 100%;
        text-align: left;
        margin-right: 0.5rem;

        @include desktop {
            width: 108px;
            text-align: center;
            margin-right: unset;
        }

        img {
            margin-right: 23px;
            vertical-align: middle;
            width: 18px;
            height: 18px;

            @include tablet {
                display: inline-flex;
                margin-right: 15px;
                vertical-align: middle;
                width: auto;
                height: auto;
            }
        }
        .toggle-text {
            display: none;
            position: absolute;
            left: 30px;
            top: 3px;
            @include desktop {
                display: inline;
                position: static;
            }
        }
    }

    .language-panel {
        box-sizing: border-box;
        position: absolute;
        background-color: white;
        text-align: center;
        border: 1px solid $lightgrey;

        @media screen and (max-width: $mobile-width) {
            top: auto;
            right: 0;
            width: 100%;
        }

        @media screen and (min-width: $small-desktop-width) {
            min-width: 103px;
            top: 83px;
            right: 16.9px;
        }

        ul {
            list-style: none;
            padding: 0 15px;

            li {
                padding: 16px 0;
                margin-left: 0;

                .language-option {
                    padding: 15px 6px;
                }

                button {
                    width: 100%;
                    border-top: 1px solid #ddd;

                    @include desktop {
                        width: auto;
                    }
                }
            }
        }
    }
}

@import '../../../../styles/main.scss';

.fm-maintenance-schedule-details-header {
    margin: 64px 16px 0 16px;
    @include desktop {
        margin: 96px 16px 0 56px;
    }
    .fm-maintenance-schedule-details__title {
        font-size: 40px;
        line-height: 48px;
        color: $fm-text-grey;
    }
    .fm-maintenance-schedule-details__subtitle {
        margin: 16px 16px 0 0;
        font-size: 16px;
        line-height: 24px;
        color: $f1-text-blue;
    }
}
.maintenance-schedule-details-page {
    margin: 5% 7% 1.5% 7%;
    @include mobile {
        margin: 13% 25px 0 25px;
    }

    .maintenance-error {
        margin-bottom: 10%;

        @include mobile {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
}

.maintenance-schedule-details-page-header {
    flex-direction: row;
    justify-content: space-between;
    flex: 0.3 1;

    @include mobile {
        flex-direction: column;
    }
}

.maintenance-schedule-details-page-title {
    height: 40px;
    font-size: 34px;
    line-height: 1.29;
    font-family: $f1-font-light;
    color: $fds-color-dk-gray;
    margin-bottom: 20px;
    overflow-wrap: break-word;

    @include mobile {
        height: auto;
        font-size: 34px;
        line-height: 1.42;
        text-align: center;
    }
}

.maintenance-schedule-details-page-subtext,
.maintenance-schedule-details-page-olm-text {
    font-family: $f1-font-regular;
    color: $fds-color-dk-gray;
    line-height: 26px;
    font-size: 16px;
    a {
        color: $fds-color-primary;
        text-decoration: none;
        border-bottom: 1px solid $fds-color-primary;
    }
}
.maintenance-schedule-details-page-olm-text {
    p {
        padding-bottom: 20px;
    }
}

.maintenance-schedule-vehicle-details {
    box-shadow: 0px 3px 6px 1px lightgrey;
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    height: 116px;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;
    @include mobile {
        height: auto;
        width: 90%;
        margin-left: 24px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
    }
    h2 {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        font-size: 24px;
        line-height: 34px;
        @include mobile {
            margin: 16px 0 10px 0px;
            text-align: center;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.service-schedule-vehicle-details {
    margin: 20px 0 20px 0;
    box-shadow: 0px 3px 3px 1px $fds-color-border-md-gray;
    padding: 20px 32px 20px 32px;
    max-height: 116px;

    @include tablet {
        margin-top: 30px;
    }

    @include desktop {
        margin-top: 20px;
    }

    @include mobile {
        max-height: fit-content;
        padding: 16px 15px 15px 15px;
    }

    h2 {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        line-height: 34px;
    }

    .service-schedule-vehicle-details-wrapper {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .service-schedule-vehicle-details-left {
            .service-schedule-vehicle-details-name {
                h2 {
                    padding-top: 2px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.find-out-button {
    @include mobile {
        margin: auto;
        border: 3px;
        padding: 10px;
    }
    @include tablet {
        margin: auto;
        border: 3px;
        padding: 10px 40px 10px 40px !important;
    }
}

.service-schedule-vehicle-title-right {
    display: flex;
    flex-direction: row;
    float: right;

    .primary-button .button-label {
        line-height: 13px;
    }

    @include tablet {
        padding: 18px 0;
    }

    @include mobile {
        width: 100%;
        margin: 24px 0 20px;
        flex-direction: column;
        align-items: center;
        .primary-button .button-label {
            line-height: normal;
            white-space: normal;
            padding: 7px 0;
        }
    }

    a.primary-button {
        padding: 0 25px;
    }
}
.maintenance-schedule-article-carousel {
    .fm-carousel__container {
        background-color: $fds-color-surface;
        .fm-carousel__wrapper {
            @include desktop {
                padding: 30px 0px 0px 0px;
            }
            .fm-carousel__items > div {
                @include desktop {
                    width: 420px !important;
                }
                .fm-card__container {
                    background: $fds-color-surface;
                    .fm-card__content--container {
                        padding: 16px 16px 0px;
                        .fm-card__title {
                            font-family: $f1-font-regular;
                            color: $fm-text-grey;
                            font-size: pToR(32);
                            line-height: pToR(40);
                            margin-bottom: 0;
                            padding-bottom: 12px;
                        }
                        .fm-card__description {
                            font-family: $f1-font-regular;
                            color: $fm-text-grey;
                            font-size: pToR(16);
                            line-height: pToR(24);
                        }
                    }
                }
            }
        }
    }
}

.articles-links-container {
    margin: 50px auto !important;
}

@import './maintenance-schedule-details.lincoln.scss';

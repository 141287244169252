.brand-ford {
    .hero-tiles-container {
        text-align: center;
        width: 100%;
        padding: 0 1rem;
        position: relative;
        top: -75px;

        @include desktop {
            width: 77.5rem;
        }

        .tile-grid {
            display: grid;
            padding-bottom: 2rem;
            justify-content: center;
            grid-gap: 15px;
            grid-template-columns: 1fr;

            @include tablet {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}
